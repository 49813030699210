<template>
  <b-card no-body class="p-2">
    <b-row v-if="getSiparisData">
      <b-col cols="12" md="12" class="mb-3" v-if="!getSiparisData.siparisOdemeIstek">
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="odemeType"
            name="some-radios"
            value="online"
          >
            Online ödeme yapmak istiyorum.
          </b-form-radio>
          <b-form-radio
            v-model="odemeType"
            name="some-radios"
            value="dekont"
          >
            Ödeme yaptım dekont yüklemek istiyorum.
          </b-form-radio>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="getSiparisData">
      <b-col cols="12" md="12" class="mb-3" v-if="odemeType === 'online' && getSiparisData.siparisOdemeIstek === null">
        <siparis-odeme-online :siparisData="siparisData" />
      </b-col>
      <b-col cols="12" md="12" class="mb-3" v-if="odemeType === 'dekont' && getSiparisData.siparisOdemeIstek === null">
        <siparis-odeme-istek :siparisData="siparisData" />
      </b-col>
      <b-col cols="12" md="12" class="mb-3" v-if="getSiparisData.siparisOdemeIstek">
        <siparis-odeme-detay />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import SiparisOdemeDetay from './SiparisOdemeDetay.vue';
import SiparisOdemeIstek from './SiparisOdemeIstek.vue';
import SiparisOdemeOnline from './SiparisOdemeOnline.vue';

export default {
  components: {
    SiparisOdemeOnline,
    SiparisOdemeIstek,
    SiparisOdemeDetay,
  },
  props: {
    siparisData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getSiparisData() {
      return this.$store.getters['siparisCustomer/getSiparisData']
    },
  },
  data() {
    return {
      // Validation
      odemeType: null,
    };
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style scoped>
</style>
