<template>
  <div>
    <script type="application/javascript" defer src="https://www.paytr.com/js/iframeResizer.min.js" />

    <!-- payment info -->
    <transition name="fade" v-if="paymentStep === 'payment-info'">
      <b-card title="Ödeme Bilgileri">
        <validation-observer ref="simpleRules">
          <b-form class="mt-1">

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group label-for="ldOdemeTutari">
                  <label>{{ $t('Ödeme Tutarı') }}</label>
                  <validation-provider #default="{ errors }" rules="required" name="ldOdemeTutari">
                    <b-form-input 
                      type="number" placeholder="9999,99" 
                      id="ldOdemeTutari" disabled
                      v-model="paymentFrameRequest.ldOdemeTutari" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group label-for="siparisNo">
                  <label>{{ $t('Sipariş No') }}</label>
                  <validation-provider #default="{ errors }" rules="required" name="siparisNo">
                    <b-form-input 
                      type="text" disabled
                      id="siparisNo"
                      v-model="paymentFrameRequest.siparisNo" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group label-for="musteriAdSoyad">
                  <label>{{ $t('Ad') }}</label>
                  <validation-provider #default="{ errors }" rules="required" name="musteriAdSoyad">
                    <b-form-input 
                      id="musteriAdSoyad" :disabled="paymentStep === 'payment-panel'"
                      v-model="paymentFrameRequest.musteriAdSoyad" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group label-for="musteriTelefon">
                  <label>{{ $t('Telefon') }}</label>
                  <validation-provider #default="{ errors }" rules="required" name="musteriTelefon">
                    <cleave 
                      id="musteriTelefon" :disabled="paymentStep === 'payment-panel'"
                      v-model="paymentFrameRequest.musteriTelefon" :state="errors.length > 0 ? false:null" 
                      placeholder="(999) 999-9999" class="form-control" :raw="false" :options="options.phone" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group label-for="musteriEPosta">
                  <label>{{ $t('E-Posta') }}</label>
                  <validation-provider #default="{ errors }" rules="required|email" name="musteriEPosta">
                    <b-input-group>
                      <b-form-input 
                        id="musteriEPosta" :disabled="paymentStep === 'payment-panel'"
                        v-model="paymentFrameRequest.musteriEPosta" :state="errors.length > 0 ? false:null" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group label-for="musteriAdres">
                  <label>{{ $t('Adres') }}</label>
                  <validation-provider #default="{ errors }" rules="required" name="musteriAdres">
                    <b-input-group>
                      <b-form-textarea 
                        id="musteriAdres" :disabled="paymentStep === 'payment-panel'"
                        v-model="paymentFrameRequest.musteriAdres" 
                        :state="errors.length > 0 ? false:null" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col cols="12" md="12" lg="12">
                <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
                    @click="onPayment"
                    >
                    <feather-icon
                      icon="ArrowRightCircleIcon"
                      class="mr-50"
                    />
                  <span class="align-middle">{{ $t('Ödemeye Devam Et') }}</span>
                </b-button>
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>
      </b-card>
    </transition>

    <!-- payment panel -->
    <transition name="fade" v-if="paymentStep === 'payment-panel'">
      <b-card>
        <b-button
            v-if="paymentStep === 'payment-panel'"
            variant="warning"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            @click="onPaymentIptal"
            >
            <feather-icon
              icon="ArrowLeftCircleIcon"
              class="mr-50"
            />
          <span class="align-middle">{{ $t('Ödemeyi Bilgi Paneli') }}</span>
        </b-button>
        <iframe visible="true" runat="server" id="paytriframe" frameborder="0" scrolling="no" style="width: 100%;" :src="paytrFrameData.paymentFramSrc" />
        <script v-if="paytrFrameData.paymentFramSrc" type="application/javascript" defer>iFrameResize({}, '#paytriframe');</script>
      </b-card>
    </transition>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, min, max,
} from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  props: {
    siparisData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // Validation
      required,
      min,
      max,
      email,
      // Cleave options
      options: {    
        phone: {
          blocks: [0, 3, 0, 3, 4],
          delimiter: ' ',
          delimiters: ['(', ')', ' ', '-', '-'],    
          numericOnly: true,
          uppercase: false,
          lowercase: false,
        },
      },

      siparis: this.siparisData.siparis,
      paymentStep: 'payment-info',
      paymentFrameRequest: {
        kurTipi: 'TL',
        ldOdemeTutari: this.siparisData.siparis.genelToplam,
        siparisNo: this.siparisData.siparis.belgeNo,
        pSiparisId: this.siparisData.siparis.pId,
        musteriAdSoyad: null,
        musteriEPosta: null,
        musteriTelefon: null,
        musteriAdres: null,
      },
      paytrFrameData: {
        paymentFramSrc: null,
        paymentNo: null,
      },
      paymentData: null,
    };
  },
  methods: {
    fetchUserInfos() {
      this.$store.dispatch('profileSettings/fetchProfile')
        .then(response => {
          const { musteri, sTelefon, sCepTelefon, sEMail, sAd, sSoyad } = response.result;
          this.paymentFrameRequest.musteriAdSoyad = `${sAd} ${sSoyad}`;
          this.paymentFrameRequest.musteriTelefon = sCepTelefon || sTelefon;
          this.paymentFrameRequest.musteriEPosta = sEMail;
          if (musteri) {
            this.paymentFrameRequest.musteriAdres = musteri.sMusteriAdresi;
          }
        })
    },
    onPayment() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.paymentFrameRequest.paymentNo = this.paytrFrameData.paymentNo;
          this.paymentFrameRequest.paymentBaskets = this.siparis.siparisDetayList.map(item => {
            const basket = {
              urunAdi: item.stokAdi,
              urunBirimFiyat: item.birimUcreti,
              urunMiktar: item.siparisMiktari,
            };
            return basket;
          });
          this.$store.dispatch('paymentCustomer/fetchSiparisPaymentFrame', this.paymentFrameRequest)
            .then(response => {
              if (response.statusCode === 200) {
                this.paymentStep = 'payment-panel';
                this.paytrFrameData = response.result;
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },
    onPaymentIptal() {
      this.paymentStep = 'payment-info';
      this.paytrFrameData.paymentFramSrc = null;
    },
  },
  mounted() {
    this.fetchUserInfos();
  },
}
</script>

<style scoped>
</style>
