<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Detay Listesi') }}</span>
      </template>

      <siparis-detay-list v-if="optionData.siparis" :siparisData="optionData" />
    </b-tab>
    <!--/ general tab -->

    <!-- odeme tab -->
    <b-tab v-if="optionData.siparis">
      <!-- title -->
      <template #title v-if="optionData.siparis.siparisDurumu === 0">
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Sipariş Ödeme') }}</span>
      </template>

      <siparis-odeme v-if="optionData.siparis.siparisDurumu === 0" :siparisData="optionData" />
    </b-tab>
    <!--/ general tab -->
  </b-tabs>
</template>

<script>
import AlertService from '@/common/alert.service'
import StaticOptions from '@/common/options/StaticOptions';
import { datetolocale, customizeDecimalDxGrid } from '@core/utils/filter';
import { locale } from 'devextreme/localization';

import SiparisDetayList from './SiparisDetayList.vue'
import SiparisOdeme from './SiparisOdeme.vue'

export default {
  components: {
    SiparisDetayList,
    SiparisOdeme,
  },
  data() {
    return {
      optionData: {
        siparis: null,
        satisPersonelInfo: null,
      },
    }
  },
  methods: {
    fetchSiparis() {
      const paramId = this.$route.params.id;
      if (paramId) {
        this.$store.dispatch('siparisCustomer/fetchSiparisSingle', { siparisId: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              const { malTutari, kdvTutari, genelToplam, belgeTarih, siparisDurumu } = response.result.siparis;
              response.result.siparis.malTutariFormatted = customizeDecimalDxGrid(malTutari)
              response.result.siparis.kdvTutariFormatted = customizeDecimalDxGrid(kdvTutari)
              response.result.siparis.genelToplamFormatted = customizeDecimalDxGrid(genelToplam)
              response.result.siparis.siparisDurumObject = StaticOptions.siparisDurumlari.find(x => x.value === siparisDurumu)
              response.result.siparis.belgeTarihFormatted = datetolocale(belgeTarih)
              response.result.siparis.siparisDetayList.map(detay => {
                detay.stockName = detay.manuelStokAdi ?? detay.stokAdi;
                if (detay.manuelStokAdi === '') detay.stockName = detay.stokAdi;
                return detay;
              });
              this.optionData = response.result;

              this.$store.commit('siparisCustomer/setSiparisData', response.result)
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },
  },
  mounted() {
    this.fetchSiparis();
  },
  created() {
    locale('tr')
  },
}
</script>
