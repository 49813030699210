<template>
  <div>
    <!-- payment info -->
    <transition name="fade">
      <b-card title="Ödeme Bilgileri">
        <b-form class="mt-1">

          <b-row>
            <b-col cols="12" md="6" lg="6">
              <b-form-group label-for="ldOdemeTutari">
                <label>{{ $t('Ödeme Tutarı') }}</label>
                <b-form-input 
                    type="number" placeholder="9999,99" 
                    id="ldOdemeTutari" disabled
                    v-model="siparisData.siparis.genelToplam" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" class="mt-2 mb-2">
              <b-form-group
                label="Ödeme Dekont Dosyasını Yükleyin"
                label-for="docTraCode"
              >
                <b-form-file
                  v-model="odemeIstekRequest.file"
                  id="file"
                  ref="file-input"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" lg="6">
              <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
                  @click="onPayment"
                  >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                <span class="align-middle">{{ $t('Kaydet') }}</span>
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </b-card>
    </transition>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  props: {
    siparisData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      siparis: this.siparisData.siparis,
      odemeIstekRequest: {
        file: null,
        pSiparisId: this.siparisData.siparis.pId,
      },
    };
  },
  methods: {
    onPayment() {
      if (this.odemeIstekRequest.file === null) {
        AlertService.error(this, this.$i18n.t('Dekont Dosyasını Yükleyin.'));
        return;
      }

      const odemeIstekForm = new FormData();
      odemeIstekForm.set('file', this.odemeIstekRequest.file);
      odemeIstekForm.set('pSiparisId', this.odemeIstekRequest.pSiparisId)
      this.$store.dispatch('siparisCustomer/setSiparisOdemeIstek', odemeIstekForm)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
                this.siparisData.siparisOdemeIstek = response.result;
                this.$store.commit('siparisCustomer/setSiparisData', this.siparisData)
              } else {
                AlertService.error(this, response.message)
              }
            })
    },
  },
}
</script>

<style scoped>
</style>
